<template>
  <div class="view">
    <div class="view-header">
      <div class="view-header__header">
        <Btn text="Sauvegarder et quitter" icon="arrow-left" class="notation-back-btn" :to="{
          name: 'home',
        }" />
        <Btn text="Synchroniser" color="secondary" @click="this.notationService.synchronizeNotation(reservation.id)"
          :disabled="!isOnline" />
      </div>
      <!-- <div class="view-header__footer">
        <h1 class="page-title">Résumé des notations</h1>
      </div> -->
      <div class="view-header__notation-tabs">
        <div class="notation-tabs">
          <div class="notation-tab active">
            <span>Résumé des notations</span>
          </div>
        </div>
      </div>
    </div>

    <div class="view-body">
      <Table :staticHeaders="staticHeaders" :headers="[]" :items="items" :hiddenItems="['ordre']" :tools="false"
        stickyColumn stickyHeader class="margin-bottom" style="height: auto" />
      <div class="text-align-center">
        <Btn text="Finaliser la notation" color="primary" @click="endNotationModal = true" />
      </div>
    </div>
    <Btn @click="prevStep()" class="notation-btn notation-btn--previous" color="white" icon="caret-left" round />
  </div>

  <Modal title="Finaliser la notation" :active="endNotationModal" :data="reservation"
    @modal-close="endNotationModal = false">
    <template v-slot:modal-body="{ data }">
      <p v-if="data.progression && data.progression < 100">
        La notation semble incomplète, voulez-vous vraiment la finaliser?
      </p>
      <p v-else>Voulez vous vraiment finaliser la notation ?</p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Non" @click="endNotationModal = false" />
      <Btn text="Oui" @click="endNotation(data)" color="primary" />
    </template>
  </Modal>
  <ProgressModal />
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Modal from '@/components/layout/Modal.vue'
import ProgressModal from '@/views/componentsViews/notation/ProgressModal.vue'

export default {
  name: 'SummaryView',

  components: {
    ProgressModal,
    Btn,
    Table,
    Modal,
  },
  props: {
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      isOnline: navigator.onLine,
      notation: {},
      from: null,
      staticHeaders: [],
      supHeaders: [],
      modalites: [],
      totalModa: [],
      reservation: {},
      variables: [],
      items: [],
      endNotationModal: false,
    }
  },

  mounted() {
    this.from = this.$route.query.from
    this.getNotation().then((res) => {
      this.notation = res
      this.setDatas()
      this.setHeaders()
    })

    // Global keyboard bindings
    window.addEventListener('keydown', this.managekey)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.managekey)
  },
  methods: {
    endNotation(data) {
      this.endNotationModal = false
      this.notationService.endNotation(data)
    },
    managekey(e) {
      if (e.getModifierState('Shift') && e.key === 'Enter') {
        this.prevStep()
      }
    },
    getNotation() {
      return new Promise((resolve) => {
        this.reservation = this.notationService.getItem(
          'reservations',
          this.$route.params.id,
        )
        resolve(this.reservation?.notation)
      })
    },

    setDatas() {
      const modalites = []
      const variables = []
      this.totalModa = []

      Object.keys(this.notation.frames).forEach((localisation) => {
        if (this.notation.frames[localisation].length > 0) {
          this.notation.frames[localisation].forEach((frame) => {
            let modalite = {}

            if (frame.microparcelle) {
              modalite = frame.microparcelle.modalite
            } else {
              modalite = frame.modalite
            }

            const foundModa = modalites.find((item) => item.id === modalite.id)
            if (!foundModa) {
              modalites.push(modalite)
            }

            let totalms = 0
            let prevdate = false

            frame.variables_etudiees.forEach((ve) => {
              const foundVe = variables.find((item) => item.id === ve.id)
              if (!foundVe) {
                variables.push(this.helperService.cloneObject(ve))
              }

              let index = -1
              const localValues = []

              if (this.totalModa[ve.id]) {
                index = this.totalModa[ve.id].findIndex(
                  (item) => item.id === modalite.id,
                )
              } else {
                this.totalModa[ve.id] = []
              }

              if (this.getVariableType(ve) === 'DATE' && ve.moyenne !== null) {
                if (index !== -1) {
                  const thedate = new Date(ve.moyenne).getTime()
                  if (prevdate) {
                    totalms += prevdate - thedate
                  } else {
                    totalms = thedate
                  }
                  prevdate = thedate
                  this.totalModa[ve.id][index] = {
                    id: modalite.id,
                    total: totalms + this.totalModa[ve.id][index].total,
                    nb: 1 + this.totalModa[ve.id][index].nb,
                  }
                } else {
                  const thedate = new Date(ve.moyenne).getTime()
                  prevdate = thedate
                  this.totalModa[ve.id].push({
                    id: modalite.id,
                    total: thedate,
                    nb: 1,
                  })
                }
              }

              if (this.getVariableType(ve) === 'BOOLEAN' && ve.moyenne) {
                if (index !== -1) {
                  this.totalModa[ve.id][index] = {
                    id: modalite.id,
                    total: null,
                    nb: 1 + this.totalModa[ve.id][index].nb,
                    values: this.totalModa[ve.id][index].values.concat([ve.moyenne]),
                  }
                } else {
                  this.totalModa[ve.id].push({
                    id: modalite.id,
                    total: null,
                    nb: 1,
                    values: [ve.moyenne],
                  })
                }
              }

              if (this.getVariableType(ve) === 'DECIMAL' && ve.moyenne !== null) {
                if (index !== -1) {
                  this.totalModa[ve.id][index] = {
                    id: modalite.id,
                    total: ve.moyenne + this.totalModa[ve.id][index].total,
                    nb: 1 + this.totalModa[ve.id][index].nb,
                  }
                } else {
                  this.totalModa[ve.id].push({
                    id: modalite.id,
                    total: ve.moyenne,
                    nb: 1,
                  })
                }
              }

              if (this.getVariableType(ve) === 'VARCHAR') {
                this.notation.notes.forEach((note) => {
                  if (note.variable_id === ve.id
                    && (
                      (frame.microparcelle && note.microparcelle_id === frame.microparcelle?.id)
                      || (frame.modalite && note.modalite === frame.modalite?.id)
                    )) {
                    localValues.push(note.valeurautre)
                  }
                })
                if (localValues.length > 0) {
                  if (index !== -1) {
                    if (this.totalModa[ve.id][index].values) {
                      this.totalModa[ve.id][index] = {
                        id: modalite.id,
                        values: this.totalModa[ve.id][index].values.concat(localValues),
                        nb: 1,
                      }
                    }
                  } else {
                    this.totalModa[ve.id].push({
                      id: modalite.id,
                      values: localValues,
                      nb: 1,
                    })
                  }
                }
              }

              if (this.getVariableType(ve) === 'LIST') {
                this.notation.notes.forEach((note) => {
                  if (note.variable_id === ve.id
                    && (
                      (frame.microparcelle && note.microparcelle_id === frame.microparcelle?.id)
                      || (frame.modalite && note.modalite === frame.modalite?.id)
                    )) {
                    const localListValue = ve.valeurs.find((val) => (val.id === note.valeur_liste_id))
                    localValues.push(localListValue.designation)
                  }
                })

                if (localValues.length > 0) {
                  if (index !== -1) {
                    if (this.totalModa[ve.id][index].values) {
                      this.totalModa[ve.id][index] = {
                        id: modalite.id,
                        values: this.totalModa[ve.id][index].values.concat(localValues),
                        nb: 1,
                      }
                    }
                  } else {
                    this.totalModa[ve.id].push({
                      id: modalite.id,
                      values: localValues,
                      nb: 1,
                    })
                  }
                }
              }
            })
          })
        }
      })

      modalites.sort((a, b) => a.ordre - b.ordre)
      variables.sort((a, b) => a.ordre - b.ordre)

      this.modalites = modalites
      this.variables = variables

      this.setItems()
    },

    setHeaders() {
      this.staticHeaders = ['Désignation']
      this.variables.forEach((v) => {
        let organe = null
        if (v?.organes) {
          organe = v?.organes.pop()
        }
        this.supHeaders.push(
          `Variable ${v.id}: ${v.designation}<br>Organe: ${organe ? organe.designation : '--'}<br>
          Cible: ${v?.cible?.valeur ?? '--'}<br>Unité: ${v?.unite?.valeur ?? '--'}`,
        )
      })

      this.staticHeaders = [...this.staticHeaders, ...this.supHeaders].filter(
        (h) => h !== undefined,
      )
      this.staticHeaders = this.staticHeaders.sort((a, b) => a.ordre - b.ordre)
    },

    setItems() {
      this.items = this.modalites.map((mod) => {
        let modalite = {
          ordre: mod.ordre,
          designation: `${mod.designation}`,
        }

        this.variables.forEach((ve) => {
          if (this.totalModa[ve.id]) {
            const veData = {}
            veData[`ve_${ve.id}`] = '--'

            const index = this.totalModa[ve.id].findIndex(
              (item) => item.id === mod.id,
            )

            if (index !== -1 && this.totalModa[ve.id][index].nb !== 0) {
              if (this.getVariableType(ve) === 'DATE') {
                const moyenne = this.totalModa[ve.id][index].total
                  / this.totalModa[ve.id][index].nb
                const date = new Date(Math.ceil(moyenne))
                veData[`ve_${ve.id}`] = date.toLocaleDateString()
              } else if (this.getVariableType(ve) === 'BOOLEAN') {
                const total = this.totalModa[ve.id][index].values.length
                const totalYes = (this.totalModa[ve.id][index].values.filter((itemy) => itemy === 'true').length * 100) / total
                const totalNo = (this.totalModa[ve.id][index].values.filter((itemn) => itemn === 'false').length * 100) / total

                veData[`ve_${ve.id}`] = `Oui ${this.helperService.round(totalYes, 2)}%  | Non ${this.helperService.round(totalNo, 2)}%`
              } else if (this.getVariableType(ve) === 'VARCHAR') {
                veData[`ve_${ve.id}`] = this.totalModa[ve.id][index].values.join(', ')
              } else if (this.getVariableType(ve) === 'LIST') {
                veData[`ve_${ve.id}`] = this.totalModa[ve.id][index].values.join(', ')
              } else {
                const moyenne = this.totalModa[ve.id][index].total / this.totalModa[ve.id][index].nb
                let rounded = this.helperService.round(moyenne, 2)
                if (Number.isNaN(rounded)) {
                  rounded = moyenne
                }
                veData[`ve_${ve.id}`] = rounded
              }
            }

            modalite = { ...modalite, ...veData }
          }
        })

        return modalite
      })

      this.setSubRows()
    },

    setSubRows() {
      let moyennes = {
        ordre: '---',
        designation: '<b>Moyenne</b>',
      }
      let ecarts = {
        ordre: '---',
        designation: '<b>Écart Type</b>',
      }

      this.variables.forEach((ve) => {
        if (this.totalModa[ve.id]) {
          const veAverage = {}
          veAverage[`ve_${ve.id}`] = '--'

          const veStandardDeviation = {}
          veStandardDeviation[`ve_${ve.id}`] = '--'

          const average = this.calcVeAverage(this.totalModa[ve.id])
          const standardDeviation = this.calcVarStandardDeviation(
            this.totalModa[ve.id],
            average,
          )

          if (this.getVariableType(ve) === 'DATE') {
            const date = new Date(Math.ceil(average))
            veAverage[`ve_${ve.id}`] = date.toLocaleDateString()
          } else if (this.getVariableType(ve) === 'BOOLEAN') {
            let totalYes = []
            let totalNo = []

            this.totalModa[ve.id].forEach((item) => {
              const yes = item.values.filter((itemy) => itemy === 'true')
              const no = item.values.filter((itemn) => itemn === 'false')

              totalYes = totalYes.concat(yes)
              totalNo = totalNo.concat(no)
            })

            const total = totalYes.length + totalNo.length
            const pourcentYes = (totalYes.length * 100) / total
            const pourcentNo = (totalNo.length * 100) / total

            veAverage[`ve_${ve.id}`] = `Oui ${this.helperService.round(pourcentYes, 2)}% | Non ${this.helperService.round(pourcentNo, 2)}%`
          } else if (this.getVariableType(ve) === 'DECIMAL') {
            console.log('this.totalModa[ve.id]', this.totalModa[ve.id])
            if (!Number.isNaN(average)) {
              veAverage[`ve_${ve.id}`] = this.helperService.round(average, 2)
            }

            if (!Number.isNaN(standardDeviation)) {
              veStandardDeviation[`ve_${ve.id}`] = this.helperService.round(
                standardDeviation,
                2,
              )
            }
          } else {
            if (!Number.isNaN(average)) {
              veAverage[`ve_${ve.id}`] = this.helperService.round(average, 2)
            }

            if (!Number.isNaN(standardDeviation)) {
              veStandardDeviation[`ve_${ve.id}`] = this.helperService.round(
                standardDeviation,
                2,
              )
            }
          }

          moyennes = { ...moyennes, ...veAverage }
          ecarts = { ...ecarts, ...veStandardDeviation }
        }
      })

      this.items.push(moyennes)
      this.items.push(ecarts)
    },
    calcVeAverage(data) {
      let averages = 0
      data.forEach((item) => {
        if (item.nb !== 0 && !Number.isNaN(item.total)) {
          averages += item.total / item.nb
        }
      })
      return averages / data.length
    },
    calcVarStandardDeviation(data, average) {
      let standardDeviation = null
      if (average !== 0) {
        standardDeviation = data
          .map(
            (item) => (item.total / item.nb - average)
              ** 2,
          )
          .reduce((a, b) => a + b, 0) / (data.length - 1)

        standardDeviation = Math.sqrt(standardDeviation)

        if (Number.isNaN(standardDeviation)) {
          standardDeviation = null
        }
      }
      return standardDeviation
    },
    getVariableType(ve) {
      return ve.variable !== null ? ve.variable.type.uid : ve.type.uid
    },
    prevStep() {
      let step = this.notation.frames[`${this.from}Index`]
        ? this.notation.frames[`${this.from}Index`]
        : 1

      const max = this.notation.frames[this.from]
        ? Object.keys(this.notation.frames[this.from]).length
        : 1

      if (step > max) {
        step = max
      }

      this.$router.push({
        name: 'notation',
        params: {
          id: this.$route.params.id,
          localisation: this.from ? this.from : 'terrain',
          etape: step,
        },
      })
    },
  },
}
</script>
